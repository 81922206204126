import React from 'react';
import styles from './PricingPage.module.scss';

import Page from 'components/Page';
import ScrollWrapper from 'components/ScrollWrapper';
import Container from 'components/Container';
import StandardContent from 'components/StandardContent';
import BackButton from 'components/BackButton';
import { useTranslation } from 'features/translator';

const PricingPage: React.FunctionComponent = () => {
  const translation = useTranslation();

  return (
    <Page name="Hinnasto" title={[translation.get('infoPage', 'title'), translation.get('pricingPage', 'title')]}>
      <div className={styles.component}>
        <ScrollWrapper>
          <Container>
            <div className={styles.back}>
              <BackButton></BackButton>
            </div>
            <div className={styles.header}>
              <h1 className={styles.title}>{translation.get('pricingPage', 'title')}</h1>
            </div>
            <div className={styles.content}>
              <StandardContent>
                <h2 className="h3">{translation.get('pricingPage', 'lunchSubtitle')}</h2>
                <ul>
                  <li>
                    <span>{translation.get('pricing', 'student')}</span> <span className={styles.price}>2,95€</span>
                  </li>
                  <li>
                    <span>{translation.get('pricing', 'graduate')}</span> <span className={styles.price}>5,55€</span>
                  </li>
                  <li>
                    <span>{translation.get('pricing', 'contract')}</span> <span className={styles.price}>7,30€</span>
                  </li>
                  <li>
                    <span>{translation.get('pricing', 'normal')}</span> <span className={styles.price}>9,30€</span>
                  </li>
                </ul>
                {/*}
                <h2 className="h3">{translation.get('pricingPage', 'veganSubtitle')}</h2>
                <ul>
                  <li>
                    <span>{translation.get('pricing', 'student')}</span> <span className={styles.price}>2,85€</span>
                  </li>
                  <li>
                    <span>{translation.get('pricing', 'graduate')}</span> <span className={styles.price}>5,45€</span>
                  </li>
                  <li>
                    <span>{translation.get('pricing', 'contract')}</span> <span className={styles.price}>6,90€</span>
                  </li>
                  <li>
                    <span>{translation.get('pricing', 'normal')}</span> <span className={styles.price}>8,60€</span>
                  </li>
                </ul>
                */}
                <h2 className="h3">{translation.get('pricingPage', 'specialSubtitle')}</h2>
                <ul>
                  <li>
                    <span>{translation.get('pricing', 'student')}</span> <span className={styles.price}>5,15€</span>
                  </li>
                  <li>
                    <span>{translation.get('pricing', 'graduate')}</span> <span className={styles.price}>7,70€</span>
                  </li>
                  <li>
                    <span>{translation.get('pricing', 'contract')}</span> <span className={styles.price}>8,90€</span>
                  </li>
                  <li>
                    <span>{translation.get('pricing', 'normal')}</span> <span className={styles.price}>10,90€</span>
                  </li>
                </ul>
                <h2 className="h3">{translation.get('pricingPage', 'buffet')} *</h2>
                <ul>
                  <li>
                    <span>{translation.get('pricing', 'student')}</span> <span className={styles.price}>10,50€</span>
                  </li>
                  <li>
                    <span>{translation.get('pricing', 'graduateAndContract')}</span> <span className={styles.price}>11,00€</span>
                  </li>
                  <li>
                    <span>{translation.get('pricing', 'normal')}</span> <span className={styles.price}>13,00€</span>
                  </li>
                </ul>

                <p>{translation.get('pricingPage', 'buffetText')}</p>

                <p>{translation.get('pricingPage', 'priceInformationText')}</p>
                {/*
                <h2 className="h3">{translation.get('pricingPage', 'dessertSubtitle')}</h2>
                <ul>
                  <li>
                    <span>{translation.get('pricingPage', 'dessertPricePrefix')}</span>{' '}
                    <span className={styles.price}>1,20€</span>
                  </li>
                </ul>
                */}
                {/* <hr />
                <h2 className="h3">Infokeskus</h2>
                <ul>
                  <li>
                    <span>{translation.get('pricingPage', 'buffet')}</span> <span className={styles.price}>9,00€</span>
                  </li>
                  <li>
                    <span>{translation.get('pricingPage', 'vegetarianSaladBuffet')}</span>{' '}
                    <span className={styles.price}>7,00€</span>
                  </li>
                  <li>
                    <span>{translation.get('pricingPage', 'vegetarianSaladBuffetStudent')}</span>{' '}
                    <span className={styles.price}>4,90€</span>
                  </li>
                  <li>
                    <span>{translation.get('pricingPage', 'soupLunch')}</span>{' '}
                    <span className={styles.price}>6,50€</span>
                  </li>
                  <li>
                    <span>{translation.get('pricingPage', 'soupLunchStudent')}</span>{' '}
                    <span className={styles.price}>2,60€</span>
                  </li>
                </ul> */}
              </StandardContent>
            </div>
          </Container>
        </ScrollWrapper>
      </div>
    </Page>
  );
};

export default PricingPage;
